import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
// import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    form: null,
    formId: null,
    entryId: null,
    entry: null,
    formdata: {},
    formFieldsNotValid: [],
    filter: '{"_state": true}',
    randomCode: null,
    included: [],
    activeSectionKey: null,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getFormId(state) {
      return state.formId;
    },
    getForm(state) {
      return state.form;
    },
    getFormAttr(state) {
      return state.form.attributes;
    },
    getSections(state) {
      let sections = state.form.attributes.sections;
      if (sections) {
        return sections;
      }
      return [];
    },
    getActiveSection(state) {
      let sections = state.form.attributes.sections;
      let section = sections.find(
        (item) => item.key === state.activeSectionKey
      );
      return section;
    },
    getActiveSectionKey(state) {
      return state.activeSectionKey;
    },
    getEntryId(state) {
      return state.entryId;
    },
    getEntry(state) {
      return state.entry;
    },
    getFormdata(state) {
      return state.formdata;
    },
    getFormFieldsNotValid(state) {
      return state.formFieldsNotValid;
    },
    getFilter(state) {
      return state.filter;
    },
    getRandomCode(state) {
      return state.randomCode;
    },
    getIncluded(state) {
      return state.included;
    },
    getStyleJson(state) {
      const transformedJson = {};
      state.form.attributes.styling.forEach((item) => {
        transformedJson[item.type] = item.style;
      });
      return transformedJson;
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setFormId(state, formId) {
      console.log(formId);
      state.formId = formId;
    },
    setForm(state, form) {
      state.form = form;
    },
    setActiveSectionKey(state, value) {
      state.activeSectionKey = value;
    },
    setEntryId(state, entryId) {
      state.entryId = entryId;
    },
    setEntry(state, entry) {
      state.entry = entry;
    },
    setFormdata(state, formdata) {
      state.formdata = { ...formdata };
    },
    setFormFieldsNotValid(state, formFieldsNotValid) {
      if (!state.formFieldsNotValid.includes(formFieldsNotValid)) {
        state.formFieldsNotValid.push(formFieldsNotValid);
        state.formFieldsNotValid = state.formFieldsNotValid.filter(
          (n) => n !== "" && n !== null
        );
      }
    },
    unsetFormFieldsNotValid(state, formFieldsNotValid) {
      // Remove all occurrences of the provided formFieldsNotValid from the array
      state.formFieldsNotValid = state.formFieldsNotValid.filter(
        (field) => field !== formFieldsNotValid
      );
    },
    resetFormFieldsNotValid(state, data) {
      state.formFieldsNotValid = data;
    },
    setDynamicFormdata(state, { key, value }) {
      // Update or add a key-value pair dynamically
      state.formdata[key] = value;
    },
    removeFormdataKey(state, key) {
      // Remove a key from the dynamicObject
      delete state.formdata[key];
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setRandomCode(state, randomCode) {
      state.randomCode = randomCode;
    },
    setIncluded(state, included) {
      state.included = included;
    },
    resetData(state) {
      state.form = null;
      state.formId = null;
      state.entryId = null;
      state.entry = null;
      state.formdata = {};
    },
  },
  actions: {
    fetchForm(state) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_WEBTOKEN;
      let apiurl = process.env.VUE_APP_APIURL;
      axios
        .get(apiurl + "forms/" + state.getters.getFormId, {
          params: {},
          headers: {
            "api-key": token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          state.commit("setForm", response.data.data);
          state.commit("setIncluded", response.data.included);

          //styling
          let css = "";
          response.data.data.attributes.styling.forEach((item) => {
            const { type, style } = item;

            const cssRule = `.${type} {\n`;

            let properties = "";
            for (const key in style) {
              let value = style[key];
              if (key === "border-radius") {
                value = `${value}px`; // Add "px" unit to border-radius
              }
              properties += `  ${key}: ${value} !important;\n`;
            }

            css += `${cssRule}${properties}}\n\n`;
          });

          const cssContent = css;
          // Create a new style element
          const styleElement = document.createElement("style");
          styleElement.setAttribute("type", "text/css");

          if (styleElement.styleSheet) {
            // For IE8 and below
            styleElement.styleSheet.cssText = cssContent;
          } else {
            // For modern browsers
            styleElement.appendChild(document.createTextNode(cssContent));
          }

          // Append the style element to the document's head
          document.head.appendChild(styleElement);

          document.title = response.data.data.attributes.data.name;

          // set the first section as active
          let firstSection = response.data.data.attributes.sections[0];
          state.commit("setActiveSectionKey", firstSection.key);

          state.commit("setLoading", false);
        })
        .catch((err) => {
          console.log(err);
          state.commit("setForm", null);
          state.commit("setIncluded", []);
          state.commit("setFormdata", []);
          state.commit("resetFormFieldsNotValid", []);
          // window.location.href = "https://publey.com/";
        });
    },
    saveEntry(state) {
      state.commit("setLoading", true);
      let token = process.env.VUE_APP_WEBTOKEN;
      let apiurl = process.env.VUE_APP_APIURL;
      axios
        .post(
          apiurl + "forms/" + this.getters.getFormId + "/entries",
          this.getters.getFormdata,
          {
            headers: {
              "api-key": token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          state.commit("setLoading", false);
          state.commit("setEntry", response.data);
          state.commit("setEntryId", response.data._id);

          const resultpageIndex = this.getters.getSections.findIndex(
            (item) => item.type === "result"
          );
          if (resultpageIndex !== -1) {
            let section = this.getters.getSections[resultpageIndex];
            state.commit("setActiveSectionKey", section.key);
          }
        })
        .catch((err) => console.log(err));
    },
    setDynamicFormdata(state, payload) {
      state.commit("setDynamicFormdata", payload);
    },
    removeFormdataKey(state, key) {
      state.commit("removeFormdataKey", key);
    },
  },
  modules: {},
});
