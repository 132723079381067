<template>
  <section class="p-5 mt-3 bg-light text-center home-featured">
    <div class="d-flex align-items-center p-5">
      <div class="container text-center">
        <h1 class="display-1 fw-bold mb-4 text-white">404</h1>
        <p class="lead mb-4 text-white">
          Oops! The page you requested was not found.
        </p>
        <router-link class="btn btn-primary" to="/">Go Home</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageNotFound",
  mounted() {
    document.title = "Publey - 404";
  },
};
</script>

<style scoped>
.cover {
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.fit-image {
  width: 100%;
  object-fit: cover;
  /* height: 300px; only if you want fixed height */
}
.home-featured {
  background-color: #e5e5e5;
  /* background-image: linear-gradient(to top right, purple, violet, orange); */
  background: linear-gradient(317deg, #c4007a, #009ce0);
  background-size: 600% 600%;
  -webkit-animation: gradanimate 10s ease infinite;
  animation: gradanimate 10s ease infinite;
}
@-webkit-keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
@keyframes gradanimate {
  0% {
    background-position: 2% 0%;
  }
  50% {
    background-position: 99% 100%;
  }
  100% {
    background-position: 2% 0%;
  }
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}
</style>
