<template lang="html">
  <div v-if="block">
    <component :is="block.block_data.variable_style">
      {{ getVariableType(block.block_data.variable_type) }}
    </component>
  </div>
</template>

<script>
export default {
  name: "BlckVariable",
  props: {
    block_key: String,
  },
  computed: {
    block() {
      let activeSection = this.$store.getters.getActiveSection;
      if (!activeSection.blocks) {
        activeSection.blocks = [];
      }
      let blocks = activeSection.blocks;
      let block = blocks.find((item) => item.block_key === this.block_key);
      return block;
    },
    formAttr() {
      return this.$store.getters.getFormAttr;
    },
    activeSection() {
      return this.$store.getters.getActiveSection;
    },
  },
  methods: {
    getVariableType(variable_type) {
      if (variable_type === "formname") {
        return this.formAttr.data.name;
      } else if (variable_type === "sectioname") {
        return this.activeSection.name;
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
