<template>
  <div class="home"></div>
</template>

<script>
export default {
  name: "HomeView",
  mounted() {
    document.title = "Publey - Not Found";
    window.location.href = "https://publey.com/";
  },
};
</script>
