<template v-if="$store.getters.getFormdata" lang="html">
  <label class="form-label">
    {{
      block.block_data.field_label_placeholder +
      (block.block_data.field_required ? " *" : "")
    }}
  </label>
  <div class="d-grid gap-2">
    <button
      v-for="option in block.block_data.field_options"
      v-bind:key="option"
      type="button"
      class="btn btn-lg checkbox-field"
      :class="[isChecked(option) ? 'opt-checked' : 'opt-unchecked']"
      @click="addRemoveOption(option)"
    >
      <i class="bi bi-square checkbox-icon" v-if="!isChecked(option)"></i>
      <i class="bi bi-check-square checkbox-icon" v-if="isChecked(option)"></i>
      {{ option }}
    </button>
  </div>
  <div class="form-text" v-if="block.block_data.field_message">
    {{ block.block_data.field_message }}
  </div>
</template>

<script>
export default {
  name: "BlckCheckbox",
  props: {
    block_key: String,
  },
  data() {
    return {
      checked: [], // Initialize checked as an empty array
    };
  },
  computed: {
    dynamicFormdata: {
      get() {
        return this.$store.getters.getFormdata;
      },
      set() {},
    },
    block() {
      let activeSection = this.$store.getters.getActiveSection;
      let blocks = activeSection.blocks;
      let block = blocks.find((item) => item.block_key === this.block_key);
      return block;
    },
  },
  created() {
    if (!this.dynamicFormdata[this.block.block_data.field_name]) {
      this.dynamicFormdata[this.block.block_data.field_name] = [];
    } else {
      this.checked = this.dynamicFormdata[this.block.block_data.field_name];
    }
    // Check if field is required
    this.handleFieldValidation();
  },
  methods: {
    isChecked(option) {
      // console.log(option);
      if (this.checked.indexOf(option) !== -1) {
        return true;
      }
      return false;
    },
    addRemoveOption(option) {
      const isChecked = this.checked.includes(option);
      // console.log(isChecked);
      this.checked = isChecked
        ? this.checked.filter((checkedAnswer) => checkedAnswer !== option)
        : [...this.checked, option];
      this.$store.commit("setDynamicFormdata", {
        key: this.block.block_data.field_name,
        value: this.checked,
      });
      this.handleFieldValidation();
    },
    handleFieldValidation() {
      // Check if field is required
      if (
        this.block.block_data.field_required !== null &&
        this.block.block_data.field_required
      ) {
        // console.log(this.block.block_data.field_required);
        // Check if the value of the key/value pair is not empty
        if (this.dynamicFormdata[this.block.block_data.field_name].length > 0) {
          // Remove the key from the "FormFieldsNotValid" array
          this.$store.commit(
            "unsetFormFieldsNotValid",
            this.block.block_data.field_name
          );
        } else {
          // If the value is empty, add the key to the array
          this.$store.commit(
            "setFormFieldsNotValid",
            this.block.block_data.field_name
          );
        }
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
