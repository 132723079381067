<template lang="html">
  <div v-for="block in blocks" v-bind:key="block" class="mb-3">
    <div v-if="block.block_type === 'variable'">
      <BlckVariable :block_key="block.block_key" />
    </div>
    <div v-if="block.block_type === 'text'">
      <BlckText :block_key="block.block_key" />
    </div>
    <div v-if="block.block_type === 'image'">
      <BlckImage :block_key="block.block_key" />
    </div>
    <div v-if="block.block_type === 'input_text'">
      <BlckInputText :block_key="block.block_key" />
    </div>
    <div v-if="block.block_type === 'input_number'">
      <BlckInputText :block_key="block.block_key" />
    </div>
    <div v-if="block.block_type === 'input_email'">
      <BlckInputEmail :block_key="block.block_key" />
    </div>
    <div v-if="block.block_type === 'select'">
      <BlckSelect :block_key="block.block_key" />
    </div>
    <div v-if="block.block_type === 'checkbox'">
      <BlckCheckbox :block_key="block.block_key" />
    </div>
    <div v-if="block.block_type === 'textarea'">
      <BlckTextarea :block_key="block.block_key" />
    </div>
  </div>
  <!-- <small>{{ blocks }}</small> -->
</template>

<script>
import BlckCheckbox from "@/components/BlckCheckbox.vue";
import BlckImage from "@/components/BlckImage.vue";
import BlckInputText from "@/components/BlckInputText.vue";
import BlckInputEmail from "@/components/BlckInputEmail.vue";
import BlckSelect from "@/components/BlckSelect.vue";
import BlckText from "@/components/BlckText.vue";
import BlckTextarea from "@/components/BlckTextarea.vue";
import BlckVariable from "@/components/BlckVariable.vue";

export default {
  name: "BlockLooper",
  components: {
    BlckCheckbox,
    BlckImage,
    BlckInputEmail,
    BlckInputText,
    BlckSelect,
    BlckText,
    BlckTextarea,
    BlckVariable,
  },
  computed: {
    activeSection() {
      return this.$store.getters.getActiveSection;
    },
    form() {
      return this.$store.getters.getForm;
    },
    blocks() {
      let activeSection = this.activeSection;
      if (!activeSection.blocks) {
        activeSection.blocks = [];
      }
      return activeSection.blocks;
    },
  },
};
</script>

<style lang="css" scoped></style>
