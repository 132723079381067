<template lang="html">
  <div v-if="block">
    <div v-html="innerValue(block.block_data.text)"></div>
  </div>
</template>

<script>
import { Marked } from "@ts-stack/markdown";

export default {
  name: "BlckText",
  props: {
    block_key: String,
  },
  computed: {
    block() {
      let activeSection = this.$store.getters.getActiveSection;
      let blocks = activeSection.blocks;
      let block = blocks.find((item) => item.block_key === this.block_key);
      return block;
    },
  },
  methods: {
    innerValue(value) {
      if (!value) {
        value = " ";
      }
      return Marked.parse(value);
    },
  },
};
</script>

<style lang="css" scoped></style>
