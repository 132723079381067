<template v-if="$store.getters.getFormdata" lang="html">
  <input
    v-model="dynamicFormdata[block.block_data.field_name]"
    @input="onInput"
    class="form-control form-control-lg field no-focusborder"
    :type="block.block_data.field_type"
    ref="inputfield"
    :placeholder="
      block.block_data.field_label_placeholder +
      (block.block_data.field_required ? ' *' : '')
    "
    :required="block.block_data.field_required"
  />
  <div class="form-text" v-if="block.block_data.field_message">
    {{ block.block_data.field_message }}
  </div>
</template>

<script>
export default {
  name: "BlckInputEmail",
  props: {
    block_key: String,
  },
  computed: {
    dynamicFormdata: {
      get() {
        return this.$store.getters.getFormdata;
      },
      // set() {},
    },
    block() {
      let activeSection = this.$store.getters.getActiveSection;
      let blocks = activeSection.blocks;
      let block = blocks.find((item) => item.block_key === this.block_key);
      return block;
    },
  },
  watch: {
    dynamicFormdata: {
      deep: true,
      handler(input) {
        this.$store.commit("setDynamicFormdata", {
          key: input.key,
          value: input.value,
        });
        this.handleFieldInput();
      },
    },
  },
  created() {
    this.handleFieldInput();
  },
  methods: {
    handleFieldInput() {
      if (
        this.block.block_data.field_required !== null &&
        this.block.block_data.field_required
      ) {
        this.handleFieldValidation();
      } else {
        if (this.dynamicFormdata[this.block.block_data.field_name]) {
          this.handleFieldValidation();
        } else {
          this.$store.commit(
            "unsetFormFieldsNotValid",
            this.block.block_data.field_name
          );
        }
      }
    },
    handleFieldValidation() {
      // email validation regex pattern
      const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
      // Check if the value matches the phone number pattern
      if (
        !emailPattern.test(
          this.dynamicFormdata[this.block.block_data.field_name]
        )
      ) {
        // If the value is not a valid phone number, add the key to the "FormFieldsNotValid" array
        this.$store.commit(
          "setFormFieldsNotValid",
          this.block.block_data.field_name
        );
      } else {
        // If the value is a valid phone number, remove the key from the "FormFieldsNotValid" array
        this.$store.commit(
          "unsetFormFieldsNotValid",
          this.block.block_data.field_name
        );
      }
    },
    onInput() {
      // // Strip out all non-numeric characters from the input value
      // const plusAndDigitsOnlyValue = event.target.value.replace(/[^+\d]/g, "");
      //
      // // Update the input value with the numeric-only value
      // this.dynamicFormdata[this.field.field_name] = plusAndDigitsOnlyValue;

      this.handleFieldInput();
    },
  },
};
</script>

<style lang="css" scoped></style>
