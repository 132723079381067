<template>
  <div class="form vh-100">
    <header></header>
    <main class="p-2">
      <router-view :key="$route.path" />
    </main>
    <div class="footer spacer" style="height: 50px"></div>
    <footer id="footer">
      <div class="container p-2 text-light">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <small>
              <span>&copy; </span>
              <a href="https://publey.com" role="button" class="text-light">
                Publey lead forms
              </a>
            </small>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "styles/custom.scss";

@import "bootstrap/scss/bootstrap.scss";
// @import '~bootstrap-vue/src/index.scss';

$height-footer: 50px;

html {
  min-height: 100%;
  position: relative;

  #footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #999;
    border-width: 1px;
    border-color: #555;
    z-index: 1; /* Place the footer above the main content */
    box-shadow: inset 0px 5px 10px rgba(0, 0, 0, 0.2); /* Add an inset shadow */
  }
}
</style>
