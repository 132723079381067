<template v-if="$store.getters.getFormdata" lang="html">
  <input
    v-model="dynamicFormdata[block.block_data.field_name]"
    class="form-control form-control-lg field no-focusborder"
    :type="block.block_data.field_type"
    ref="inputfield"
    :placeholder="
      block.block_data.field_label_placeholder +
      (block.block_data.field_required ? ' *' : '')
    "
    :required="block.block_data.field_required"
  />
  <div class="form-text" v-if="block.block_data.field_message">
    {{ block.block_data.field_message }}
  </div>
</template>

<script>
export default {
  name: "BlckInputText",
  props: {
    block_key: String,
  },
  computed: {
    dynamicFormdata: {
      get() {
        return this.$store.getters.getFormdata;
      },
      set() {},
    },
    block() {
      let activeSection = this.$store.getters.getActiveSection;
      let blocks = activeSection.blocks;
      let block = blocks.find((item) => item.block_key === this.block_key);
      return block;
    },
  },
  watch: {
    dynamicFormdata: {
      deep: true,
      handler(input) {
        this.$store.commit("setDynamicFormdata", {
          key: input.key,
          value: input.value,
        });
        this.handleFieldValidation();
      },
    },
  },
  created() {
    // Check if field is required
    this.handleFieldValidation();
  },
  methods: {
    handleFieldValidation() {
      // Check if field is required
      if (
        (this.block.block_data.field_required !== null &&
          this.block.block_data.field_required) ||
        this.dynamicFormdata[this.block.block_data.field_name]
      ) {
        // Check if the value of the key/value pair is not empty
        if (this.dynamicFormdata[this.block.block_data.field_name]) {
          // Remove the key from the "FormFieldsNotValid" array
          this.$store.commit(
            "unsetFormFieldsNotValid",
            this.block.block_data.field_name
          );
        } else {
          // If the value is empty, add the key to the array
          this.$store.commit(
            "setFormFieldsNotValid",
            this.block.block_data.field_name
          );
        }
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
