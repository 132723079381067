<template lang="html">
  <div v-if="block">
    <div v-if="assetForm" class="clearfix">
      <img
        :src="assetForm.asset_url"
        :alt="assetForm.asset_name"
        :class="getPlacementClass() + ' img-fluid'"
        :width="block.block_data.image_width"
        :height="block.block_data.image_height"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BlckImage",
  props: {
    block_key: String,
  },
  computed: {
    block() {
      let activeSection = this.$store.getters.getActiveSection;
      if (!activeSection.blocks) {
        activeSection.blocks = [];
      }
      let blocks = activeSection.blocks;
      let block = blocks.find((item) => item.block_key === this.block_key);
      return block;
    },
    formAttr() {
      return this.$store.getters.getFormAttr;
    },
    assetForm() {
      // Replace 'yourDesiredId' with the specific ID you want to find
      const image_id = this.block.block_data.image_id;
      // Find the object with the desired ID in the assetforms array
      return this.formAttr.assetforms.find((item) => item.id === image_id);
    },
  },
  methods: {
    getPlacementClass() {
      if (this.block.block_data.image_placement === "left") {
        return "float-start";
      } else if (this.block.block_data.image_placement === "right") {
        return "float-end";
      } else if (this.block.block_data.image_placement === "center") {
        return "mx-auto d-block";
      } else if (this.block.block_data.image_placement === "full-width") {
        return "img-fluid";
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
