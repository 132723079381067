<template lang="html">
  <div class="container form">
    <div class="row">
      <div class="col-md-6 offset-md-3 mt-4" v-if="form && !loading">
        <BlockLooper />
        <div v-if="!hasEntry">
          <div class="d-grid gap-2">
            <button
              v-if="hasNextSection"
              type="button"
              class="btn text-light btn-lg next-button"
              @click="nextSection"
              :disabled="nextOrSubmitDisabled == true"
            >
              <span>
                <span v-if="form.attributes.data.next_btn_txt">
                  {{ form.attributes.data.next_btn_txt }}
                </span>
                <span v-else>Next</span>
                <i class="bi bi-chevron-right"></i>
              </span>
            </button>
            <button
              v-if="!hasNextSection && hasFormdata"
              type="button"
              class="btn text-light btn-lg submit-button"
              @click="submitForm"
              :disabled="nextOrSubmitDisabled == true"
            >
              <span v-if="form.attributes.data.sbmt_btn_txt">
                {{ form.attributes.data.sbmt_btn_txt }}
              </span>
              <span v-else>Submit</span>
              &nbsp;<i class="bi bi-send"></i>
            </button>
            <button
              v-if="hasPreviousSection"
              type="button"
              class="btn text-light btn-lg previous-button"
              @click="previousSection"
            >
              <span>
                <i class="bi bi-chevron-left"></i>
                <span v-if="form.attributes.data.prev_btn_txt">
                  {{ form.attributes.data.prev_btn_txt }}
                </span>
                <span v-else>Previous</span>
              </span>
            </button>
          </div>
        </div>
        <div class="d-grid" v-if="hasEntry && canHaveMultipleEntries">
          <button
            type="button"
            class="btn submit-button text-light btn-lg"
            @click="resetData"
          >
            <span>
              <i class="bi bi-arrow-counterclockwise"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockLooper from "@/components/BlockLooper.vue";

export default {
  name: "FormView",
  components: {
    BlockLooper,
  },
  data: function () {
    return {
      apiurl: process.env.VUE_APP_APIURL,
      formdata: {},
    };
  },
  created() {
    if (
      !this.$store.getters.getFormId ||
      this.$route.params.formid !== this.$store.getters.getFormId
    ) {
      this.$store.commit("resetData");
    }
    this.$store.commit("setFormId", this.$route.params.formid);
    this.$store.commit("resetFormFieldsNotValid", []);

    // If has entry
    if (this.$store.getters.getEntry) {
      const resultpageIndex = this.sections.findIndex(
        (item) => item.type === "result"
      );
      if (resultpageIndex !== -1) {
        let section = this.sections[resultpageIndex];
        this.$store.commit("setActiveSectionKey", section.key);
      }
    } else {
      this.$store.dispatch("fetchForm");
    }
  },
  computed: {
    hasEntry() {
      if (this.$store.getters.getEntry) {
        return true;
      }
      return false;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    entry() {
      return this.$store.getters.getEntry;
    },
    form() {
      return this.$store.getters.getForm;
    },
    sections() {
      return this.form.attributes.sections;
    },
    formSections() {
      return this.sections.filter(
        (item) => item.type === "form" || item.type === undefined
      );
    },
    hasNextSection() {
      return this.getActiveSectionIndex() < this.formSections.length - 1;
    },
    hasPreviousSection() {
      return this.getActiveSectionIndex() > 0;
    },
    nextOrSubmitDisabled() {
      let arr = this.$store.getters.getFormFieldsNotValid;
      if (arr.length === 0) {
        return false;
      }
      return true;
    },
    canHaveMultipleEntries() {
      let formAttr = this.form.attributes;
      if (formAttr.data["allow_multiple_entries"] !== undefined) {
        return formAttr.data["allow_multiple_entries"];
      }
      return false;
    },
    hasFormdata() {
      let formdata = this.$store.getters.getFormdata;
      if (formdata !== null && Object.keys(formdata).length > 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    getActiveSectionIndex() {
      let activeSectionKey = this.$store.getters.getActiveSectionKey;
      const activeIndex = this.formSections.findIndex(
        (section) => section.key === activeSectionKey
      );
      return activeIndex;
    },
    nextSection() {
      const activeIndex = this.getActiveSectionIndex();
      if (activeIndex !== -1 && activeIndex < this.formSections.length - 1) {
        let nextSection = this.formSections[activeIndex + 1];
        this.$store.commit("setActiveSectionKey", nextSection.key);
      }
    },
    previousSection() {
      this.$store.commit("resetFormFieldsNotValid", []);
      //## This is OBSOLETE
      if (this.hasPreviousSection) {
        this.currentSectionIndex--;
      }
      //## This is OBSOLETE

      const activeIndex = this.getActiveSectionIndex();
      if (activeIndex !== -1 && activeIndex > 0) {
        let prevSection = this.formSections[activeIndex - 1];
        this.$store.commit("setActiveSectionKey", prevSection.key);
      }
    },
    submitForm() {
      this.generateRandomCode();
      this.$store.dispatch("saveEntry");
    },
    generateRandomCode() {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let code = "";
      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters[randomIndex];
      }
      this.$store.commit("setRandomCode", code);
    },
    resetData() {
      this.$store.commit("setEntry", null);
      this.$store.commit("setEntryId", null);
      this.$store.commit("setFormdata", null);
      this.$store.dispatch("fetchForm");
    },
  },
};
</script>

<style></style>
